import { SecondaryPackingRptConstants } from "../constants";

export function SecondaryPackingReport(state = {}, action) {
    switch (action.type) {
        case SecondaryPackingRptConstants.LOADING_REQUEST:
            return {
                ...state,
                loding: action.isbool
            };
        case SecondaryPackingRptConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SecondaryPackingRptConstants.GETALL_SUCCESS:
            return {
                ...state,
                secondaryitems: action.datas
            };
        case SecondaryPackingRptConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case SecondaryPackingRptConstants.GETEXPORT_REQUEST:
            return {
                ...state,
                exporting: true
            };
        case SecondaryPackingRptConstants.GETEXPORT_SUCCESS:
            return {
                ...state,
                exportitems: action.datas,
                isDownload: state.isDownload == null ? 1 : state.isDownload + 1
            };
        case SecondaryPackingRptConstants.GETEXPORT_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case SecondaryPackingRptConstants.GETVENDORDDL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SecondaryPackingRptConstants.GETVENDORDDL_SUCCESS:
            return {
                ...state,
                vendorDDL: action.datas
            };
        case SecondaryPackingRptConstants.GETVENDORDDL_FAILURE:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }

}